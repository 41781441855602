<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card class="my-card m-0" @scroll.passive="handleScroll">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            @row-dblclicked="editForm"
            @row-clicked="clicked"
            :items="displayedData"
            :fields="fields"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              ></b-form-checkbox>
            </template>
            <template #cell(id)="data">
              {{ data.value }}
            </template>
            <template #cell(name)="data">
              {{ data.value }}
            </template>
            <template #cell(level)="data">
              {{ data.value }}
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import lazyLoading from "@/@core/mixins/utils/lazyLoading";
export default {
  mixins: [lazyLoading],
  props: ["dataTable"],
  data() {
    return {
      fields: [
        { key: "id", label: "Id", sortable: true, thStyle: { width: "40px" } },
        {
          key: "name",
          label: "Имя",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "level",
          label: "Уровень",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
    };
  },
  methods: {
    editForm(id) {
      this.$emit("editForm", id);
    },
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;

      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();
      cellSelect();
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/table.scss";
</style>
